<template>
    <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
    <div style="height: 100%">
        <div class="body-content-overlay" :class="{'show': mqShallShowLeftSidebar}" @click="mqShallShowLeftSidebar = false" />
        <div class="todo-app-list">
    
            <b-alert v-height-fade.appear :show="dismissCountDown" dismissible fade variant="danger" class="mb-0" @dismiss-count-down="countDownChanged">
                <div class="alert-body">
                    <span>{{errorMessage}}</span>
                </div>
            </b-alert>
            <!-- App Searchbar Header -->
            <div class="app-fixed-search d-flex align-items-center">
    
                <!-- Toggler -->
                <div class="sidebar-toggle d-block d-lg-none ml-1">
                    <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="mqShallShowLeftSidebar = true" />
                </div>
    
                <!-- Searchbar -->
                <div class="d-flex align-content-center justify-content-between w-100" v-show="showTable">
                    <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                            <feather-icon icon="SearchIcon" class="text-muted" />
                        </b-input-group-prepend>
                        <b-form-input v-model="searchTerm" placeholder="Search Contacts" type="text" class="d-inline-block" />
                    </b-input-group>
                </div>
    
            </div>
    
            <!-- Todo List -->
            
                <h4 v-show="isloadingContacts"><b-spinner big v-show="isloadingContacts" style="margin-left: 10%" />  Please wait as we retrieve contacts for {{group_name}}...</h4>
            <vue-perfect-scrollbar v-show="!isloadingContacts" :settings="perfectScrollbarSettings" class="scroll-area" style="padding:10px 5px 5px 5px;height: calc(100% - 3.56rem);background-color: #fff;">
                <draggable v-model="items" handle=".draggable-task-handle" tag="ul" class="todo-task-list media-list">
                    <b-form ref="form" :class="{'show': items.length}">
                        <b-row ref="row">
    
                            
                            <b-col lg="2" md="2" v-show="showTable">
                                <b-input-group-prepend>
                                    <b-form-select v-model="countryCode" :options="countryCodeOptions" />
                                </b-input-group-prepend>
                            </b-col>
                            <b-col lg="5" md="5" v-show="showTable">
    
                                <b-form-file v-model="file" placeholder="Choose a contact upload csv file" drop-placeholder="Drop file here..." accept=".csv" />
    
                            </b-col>
                            <b-col lg="1" md="1" class="mb-50" v-show="showTable">
                                <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="UploadContacts">
                                    <feather-icon icon="UploadIcon" class="mr-25" />
                                </b-button>
                            </b-col>
                            <!-- <b-col lg="1" md="1" class="mb-50" v-show="showTable">
                                <b-button v-ripple.200="'rgba(234, 84, 85, 0.15)'" variant="success" @click="DownloadContacts">
                                    <b-spinner small v-show="isLoading" />
                                    <feather-icon v-show="!isLoading" icon="DownloadIcon" class="mr-45" />
                                </b-button>
                            </b-col> -->
                            <b-col lg="1" md="1" class="mb-50" v-show="showTable">
                                <b-button v-ripple.200="'rgba(234, 84, 85, 0.15)'" variant="warning" v-b-modal.modal-edit-contact>
    
                                    <feather-icon v-show="!isLoading" icon="EditIcon" class="mr-45" />
                                </b-button>
                            </b-col>
                             <b-col lg="1" md="1" class="mb-50" v-show="showTable">
                                <b-button v-ripple.200="'rgba(234, 84, 85, 0.15)'" variant="danger" v-b-modal.modal-trash-contact>
    
                                    <feather-icon v-show="!isLoading" icon="TrashIcon" class="mr-45" />
                                </b-button>
                            </b-col>
                        </b-row>
                       
                        <b-row>
                            <b-col md="3" xl="3" class="mb-1" v-show="showTable">
    
                                <!-- basic -->
                                <b-form-group label-for="basicInput">
                                    <b-form-input id="basicInput" placeholder="Enter Phone" v-model="phone" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" xl="3" class="mb-1" v-show="showTable">
    
                                <!-- basic -->
                                <b-form-group label-for="basicInput">
                                    <b-form-input id="basicInput" placeholder="Enter Custom1" v-model="custom1" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3" xl="3" class="mb-1" v-show="showTable">
    
                                <!-- basic -->
                                <b-form-group label-for="basicInput">
                                    <b-form-input id="basicInput" placeholder="Enter Custom2" v-model="custom2" />
                                </b-form-group>
                            </b-col>
                            <b-col lg="3" md="3" class="mb-50" v-show="showTable">
                                <b-form-group label-for="basicInput">
                                    <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" variant="outline-danger" @click="synContacts">
                                        <feather-icon icon="PlusIcon" class="mr-25" />
                                        <span><small>Add Contact</small></span>
                                    </b-button>
                                </b-form-group>
                            </b-col>
                        </b-row>
                         <b-row>
                        <p>Total Contacts for <strong>{{group_name}}</strong>: {{totalContacts | formatNumber}}</p>
                        </b-row>
                    </b-form>
    
                    <b-row ref="inboxList" v-show="showTable">
                        <vue-good-table :columns="columns" :rows="items" :rtl="direction" :search-options="{
                                enabled: true,
                                externalQuery: searchTerm }" :pagination-options="{
                                enabled: true,
                                perPage:pageLength
                            }">
                            <template slot="table-row" slot-scope="props">
    
                                <!-- Column: Name -->
                                <div v-if="props.column.field === 'fullName'" class="text-nowrap">
                                    <b-avatar :src="props.row.avatar" class="mx-1" />
                                    <span class="text-nowrap">{{ props.row.fullName }}</span>
                                </div>
    
                                <!-- Column: Status -->
                                <span v-else-if="props.column.field === 'status'">
                                    <b-badge :variant="props.row.status | shortCodeStatusColor">
                                        {{ props.row.status | formatStatus}}
                                    </b-badge>
                                </span>
    
                                <!-- Column: Action -->
                                <span v-else-if="props.column.field === 'action'">
                                    <span>
                                        <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                                            <template v-slot:button-content>
                                                <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                                            </template>
                                            <b-dropdown-item @click="editUserContact(props.row)">
                                                <feather-icon icon="Edit2Icon" class="mr-50" />
                                                <span>Edit</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item  @click="disabledUserContact(props.row)" v-show="props.row.status==1">
                                                <feather-icon icon="EyeOffIcon" class="mr-50" />
                                                <span>Disabled</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item  @click="enabledUserContact(props.row)" v-show="props.row.status!=1">
                                                <feather-icon icon="EyeOffIcon" class="mr-50" />
                                                <span>Activate</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item  @click="deleteUserContact(props.row)">
                                                <feather-icon icon="TrashIcon" class="mr-50" />
                                                <span>Delete</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </span>
                                </span>
    
                                <!-- Column: Common -->
                                <span v-else>
                                    {{ props.formattedRow[props.column.field] }}
                                </span>
                            </template>
    
                            <!-- pagination -->
                            <template slot="pagination-bottom" slot-scope="props">
                                <div class="d-flex justify-content-between flex-wrap">
                                    <div class="d-flex align-items-center mb-0 mt-1">
                                        <span class="text-nowrap">
                                            Showing 1 to
                                        </span>
                                        <b-form-select v-model="pageLength" :options="['3','5','10']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                                        <span class="text-nowrap "> of {{ props.total }} entries </span>
                                    </div>
                                    <div>
                                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                                            <template #prev-text>
                                                <feather-icon icon="ChevronLeftIcon" size="18" />
                                            </template>
                                            <template #next-text>
                                                <feather-icon icon="ChevronRightIcon" size="18" />
                                            </template>
                                        </b-pagination>
                                    </div>
                                </div>
                            </template>
                        </vue-good-table>
                    </b-row>
                </draggable>
                
                <div class="no-results" v-show="!showTable">
                <b-spinner small v-show="isloadingContacts" style="margin-left: 30%" />
                <small v-show="isloadingContacts"> Please wait as we retrive contacts for  {{group_name}}...</small>
                    <h5 v-show="!isloadingContacts">No Contacts Found</h5>
                </div>
            </vue-perfect-scrollbar>
        </div>
    
        <!-- Task Handler -->
        <todo-task-handler-sidebar v-model="isTaskHandlerSidebarActive" :task="task" :clear-task-data="clearTaskData" @remove-task="removeTask" @add-task="addTask" @update-task="updateTask" />
    
        <!-- Sidebar -->
        <portal to="content-renderer-sidebar-left">
            <todo-left-sidebar :task-tags="taskTags" :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive" :class="{'show': mqShallShowLeftSidebar}" @close-left-sidebar="mqShallShowLeftSidebar = false" />
        </portal>
        <b-modal id="modal-trash-contact" cancel-variant="outline-secondary" ok-title="Confirm Delete" cancel-title="Close" centered title="Edit Contact" @ok="editContactGroup(-1)">
        <h2>Are you sure you want to Delete</h2>
        </b-modal>
        <b-modal id="modal-edit-contact" cancel-variant="outline-secondary" ok-title="Update" cancel-title="Close" centered title="Edit Contact" @ok="editContactGroup(1)">
            <b-form>
                <b-form-group label="Basic Input" label-for="basicInput">
                    <b-form-input id="basicInput" placeholder="Name" v-model="group_name" />
                </b-form-group>
                <b-form-group label="Description">
                    <b-form-textarea id="textarea-state" v-model="description" :state="description.length <= 300" placeholder="Enter only 300 characters" rows="3" />
                </b-form-group>
                 <b-form-tags v-model="customFields" no-outer-focus >
                            <template #default="{ tags, inputAttrs, inputHandlers, addTag, removeTag }">
                                <b-input-group aria-controls="my-custom-tags-list" label="Custom Fields">
                                    <input v-bind="inputAttrs" placeholder="Custom field - Press enter to add" class="form-control" v-on="inputHandlers">
                                    <b-input-group-append>
                                        <b-button variant="primary" @click="addTag()">
                                            Add
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <ul id="my-custom-tags-list" class="list-unstyled d-inline-flex flex-wrap mb-0" aria-live="polite" aria-atomic="false" aria-relevant="additions removals">
                                    <!-- Always use the tag value as the :key, not the index! -->
                                    <!-- Otherwise screen readers will not read the tag
                                    additions and removals correctly -->
                                    <b-card v-for="tag in tags" :id="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`" :key="tag" tag="li" class="shadow-none border mt-1 mr-1 mb-0" body-class="py-1 pr-2 text-nowrap">
                                        <strong>{{ tag }}</strong>
                                        <b-button variant="link" size="sm" :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`" class="py-0" @click="removeTag(tag)">
                                            remove
                                        </b-button>
                                    </b-card>
                                </ul>
                            </template>
                        </b-form-tags>
            </b-form>
        </b-modal>
        <b-modal  v-model="editUserModal" cancel-variant="outline-secondary" ok-title="Update" cancel-title="Close" centered title="Update user Contact" @ok="editContact">
            <b-form>
                <b-form-group label="Phone Input" label-for="phoneInput">
                    <b-form-input id="phoneInput" placeholder="Phone" v-model="phone" disabled />
                </b-form-group>
                <b-form-group label="Custom1 Input" label-for="Custom1Input">
                    <b-form-input id="Custom1Input" placeholder="Custom1" v-model="custom1" />
                </b-form-group>
                 <b-form-group label="Custom2 Input" label-for="Custom2Input">
                    <b-form-input id="Custom1Input" placeholder="Custom2" v-model="custom2" />
                </b-form-group>
                 <b-form-group label="Custom3 Input" label-for="Custom3Input">
                    <b-form-input id="Custom1Input" placeholder="Custom3" v-model="custom3" />
                </b-form-group>
                 <b-form-group label="Custom4 Input" label-for="Custom4Input">
                    <b-form-input id="Custom1Input" placeholder="Custom4" v-model="custom4" />
                </b-form-group>
                 <b-form-group label="Custom5 Input" label-for="Custom5Input">
                    <b-form-input id="Custom1Input" placeholder="Custom5" v-model="custom5" />
                </b-form-group>
            </b-form>
        </b-modal>
        <b-modal v-model="disableUserModal" cancel-variant="outline-secondary" ok-title="Confirm Disable" cancel-title="Close" centered title="Disable user Contact" @ok="editContact">
        <h2>Are you sure you want to disable account</h2>
        </b-modal>
        <b-modal v-model="enableUserModal" cancel-variant="outline-secondary" ok-title="Confirm Enable" cancel-title="Close" centered title="Enable user Contact" @ok="editContact">
        <h2>Are you sure you want to enable account</h2>
        </b-modal>
        <b-modal v-model="deleteUserModal" cancel-variant="outline-secondary" ok-title="Confirm Delete" cancel-title="Close" centered title="Delete user Contact" @ok="editContact">
        <h2>Are you sure you want to delete account</h2>
        </b-modal>
    
    </div>
    </template>
    
    <script>
    import store from '@/store'
    import Ripple from 'vue-ripple-directive'
    import {
        ref,
        watch,
        computed,
        onUnmounted,
    } from '@vue/composition-api'
    import {
        BFormTags,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BDropdown,
        BDropdownItem,
        BFormCheckbox,
        BBadge,
        BAvatar,
        BCard,
        BFormFile,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormDatepicker,
        BTable,
        BAlert,
        BInputGroupAppend,
        BFormSelect,
        BPagination,
        BSpinner,
        BModal,
        BFormTextarea,
    } from 'bootstrap-vue'
    import VuePerfectScrollbar from 'vue-perfect-scrollbar'
    import draggable from 'vuedraggable'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import {
        formatDate,
        avatarText
    } from '@core/utils/filter'
    import {
        useRouter
    } from '@core/utils/utils'
    import {
        useResponsiveAppLeftSidebarVisibility
    } from '@core/comp-functions/ui/app'
    import TodoLeftSidebar from './TodoLeftSidebar.vue'
    import todoStoreModule from './todoStoreModule'
    import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
    import {
        heightFade
    } from '@core/directives/animations'
    import $ from 'jquery'
    import {
        VueGoodTable
    } from 'vue-good-table'
    import moment from "moment"
    import {
        quillEditor
    } from 'vue-quill-editor'
    import numeral from "numeral";
    export default {
        components: {
            BFormTags,
            BFormInput,
            BInputGroup,
            BInputGroupPrepend,
            BDropdown,
            BDropdownItem,
            BFormCheckbox,
            BBadge,
            BAvatar,
            draggable,
            VuePerfectScrollbar,
            BFormFile,
            BCard,
            BForm,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormDatepicker,
            BTable,
            BInputGroupAppend,
            BFormSelect,
            BPagination,
            BAlert,
            BSpinner,
            BModal,
            quillEditor,
            BFormTextarea,
    
            // App SFC
            TodoLeftSidebar,
            TodoTaskHandlerSidebar,
            VueGoodTable
        },
        setup() {
            const TODO_APP_STORE_MODULE_NAME = 'app-todo'
    
            // Register module
            if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)
    
            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
            })
    
            const {
                route,
                router
            } = useRouter()
            const routeSortBy = computed(() => route.value.query.sort)
            const routeQuery = computed(() => route.value.query.q)
            const routeParams = computed(() => route.value.params)
            watch(routeParams, () => {
                // eslint-disable-next-line no-use-before-define
                fetchTasks()
            })
    
            const tasks = ref([])
    
            const sortOptions = [
                'latest',
                'title-asc',
                'title-desc',
                'assignee',
                'due-date',
            ]
            const sortBy = ref(routeSortBy.value)
            watch(routeSortBy, val => {
                if (sortOptions.includes(val)) sortBy.value = val
                else sortBy.value = val
            })
            const resetSortAndNavigate = () => {
                const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))
    
                delete currentRouteQuery.sort
    
                router.replace({
                    name: route.name,
                    query: currentRouteQuery
                }).catch(() => {})
            }
    
            const blankTask = {
                id: null,
                title: '',
                dueDate: new Date(),
                description: '',
                assignee: null,
                tags: [],
                isCompleted: false,
                isDeleted: false,
                isImportant: false,
            }
            const task = ref(JSON.parse(JSON.stringify(blankTask)))
            const clearTaskData = () => {
                task.value = JSON.parse(JSON.stringify(blankTask))
            }
    
            const addTask = val => {
                store.dispatch('app-todo/addTask', val)
                    .then(() => {
                        // eslint-disable-next-line no-use-before-define
                        fetchTasks()
                    })
            }
            const removeTask = () => {
                store.dispatch('app-todo/removeTask', {
                        id: task.value.id
                    })
                    .then(() => {
                        // eslint-disable-next-line no-use-before-define
                        fetchTasks()
                    })
            }
            const updateTask = taskData => {
                store.dispatch('app-todo/updateTask', {
                        task: taskData
                    })
                    .then(() => {
                        // eslint-disable-next-line no-use-before-define
                        fetchTasks()
                    })
            }
    
            const perfectScrollbarSettings = {
                maxScrollbarLength: 150,
            }
    
            const isTaskHandlerSidebarActive = ref(false)
    
            const taskTags = [{
                    title: 'Team',
                    color: 'primary',
                    route: {
                        name: 'apps-todo-tag',
                        params: {
                            tag: 'team'
                        }
                    }
                },
                {
                    title: 'Low',
                    color: 'success',
                    route: {
                        name: 'apps-todo-tag',
                        params: {
                            tag: 'low'
                        }
                    }
                },
                {
                    title: 'Medium',
                    color: 'warning',
                    route: {
                        name: 'apps-todo-tag',
                        params: {
                            tag: 'medium'
                        }
                    }
                },
                {
                    title: 'High',
                    color: 'danger',
                    route: {
                        name: 'apps-todo-tag',
                        params: {
                            tag: 'high'
                        }
                    }
                },
                {
                    title: 'Update',
                    color: 'info',
                    route: {
                        name: 'apps-todo-tag',
                        params: {
                            tag: 'update'
                        }
                    }
                },
    
            ]
    
            const resolveTagVariant = tag => {
                if (tag === 'team') return 'primary'
                if (tag === 'low') return 'success'
                if (tag === 'medium') return 'warning'
                if (tag === 'high') return 'danger'
                if (tag === 'update') return 'info'
                return 'primary'
            }
    
            const resolveAvatarVariant = tags => {
                if (tags.includes('high')) return 'primary'
                if (tags.includes('medium')) return 'warning'
                if (tags.includes('low')) return 'success'
                if (tags.includes('update')) return 'danger'
                if (tags.includes('team')) return 'info'
                return 'primary'
            }
    
            // Search Query
            const searchQuery = ref(routeQuery.value)
            watch(routeQuery, val => {
                searchQuery.value = val
            })
            // eslint-disable-next-line no-use-before-define
            watch([searchQuery, sortBy], () => fetchTasks())
            const updateRouteQuery = val => {
                const currentRouteQuery = JSON.parse(JSON.stringify(route.value.query))
    
                if (val) currentRouteQuery.q = val
                else delete currentRouteQuery.q
    
                router.replace({
                    name: route.name,
                    query: currentRouteQuery
                })
            }
    
            const fetchTasks = () => {
                store.dispatch('app-todo/fetchTasks', {
                        q: searchQuery.value,
                        filter: router.currentRoute.params.filter,
                        tag: router.currentRoute.params.tag,
                        sortBy: sortBy.value,
                    })
                    .then(response => {
                        tasks.value = response.data
                    })
            }
    
            fetchTasks()
    
            const handleTaskClick = taskData => {
                task.value = taskData
                isTaskHandlerSidebarActive.value = true
            }
    
            // Single Task isCompleted update
            const updateTaskIsCompleted = taskData => {
                // eslint-disable-next-line no-param-reassign
                taskData.isCompleted = !taskData.isCompleted
                updateTask(taskData)
            }
    
            const {
                mqShallShowLeftSidebar
            } = useResponsiveAppLeftSidebarVisibility()
    
            return {
                task,
                tasks,
                removeTask,
                addTask,
                updateTask,
                clearTaskData,
                taskTags,
                searchQuery,
                fetchTasks,
                perfectScrollbarSettings,
                updateRouteQuery,
                resetSortAndNavigate,
    
                // UI
                resolveTagVariant,
                resolveAvatarVariant,
                isTaskHandlerSidebarActive,
    
                // Click Handler
                handleTaskClick,
    
                // Filters
                formatDate,
                avatarText,
    
                // Single Task isCompleted update
                updateTaskIsCompleted,
    
                // Left Sidebar Responsive
                mqShallShowLeftSidebar,
            }
        },
        data() {
            return {
                isloading: false,
                file: null,
                searchTerm: "",
                startDate: "",
                endDate: "",
                perPage: 10,
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                pageOptions: [3, 5, 10],
                nextTodoId: 2,
                showTable: false,
                fields: [{
                    key: 'msisdn',
                    label: 'Phone',
                    sortable: true
                }, 'custom1', 'custom2', 'custom3', 'custom4', 'custom5', {
                    key: 'created_at',
                    label: 'Date'
                }, 'action'],
                items: [],
                countryCodeOptions: [],
                countryCode: '254',
                errorMessage: "",
                countryCodeOptions: [],
                dismissSecs: 5,
                dismissCountDown: 0,
                showDismissibleAlert: false,
                isLoading: false,
                phone: "",
                custom1: "",
                custom2: "",
                custom3: "",
                custom4: "",
                custom5: "",
                pageLength: 5,
                dir: false,
                editIsLoading: false,
                columns: [{
                        field: 'msisdn',
                        label: 'Phone'
                    },
    
                    {
                        label: 'custom1',
                        field: 'custom1'
                    },
                    {
                        label: 'custom2',
                        field: 'custom2'
                    },
                    {
                        label: 'custom3',
                        field: 'custom3'
                    },
                    {
                        label: 'custom4',
                        field: 'custom4'
                    },
                    {
                        label: 'status',
                        field: 'status',
                    },
    
                    {
                        label: 'Action',
                        field: 'action',
                    },
                ],
                rows: [],
                searchTerm: '',
                status: [{
                    '13': 'Active',
                    '11': 'Pending',
                    '12': 'Rejected',
                }, {
                    '13': 'light-success',
                    '11': 'light-primary',
                    '12': 'light-danger',
                }],
                group_name: "",
                description:"",
                customFields: [],
                editUserModal:false,
                disableUserModal: false,
                enableUserModal: false,
                deleteUserModal:false,
                contactID:"",
                statusAccount:1,
                isloadingContacts:false,
                totalContacts:0
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
            statusVariant() {
                const statusColor = {
                    /* eslint-disable key-spacing */
                    Active: 'light-success',
                    Pending: 'light-primary',
                    Rejected: 'light-danger',
                    /* eslint-enable key-spacing */
                }
    
                return status => statusColor[status]
            },
            direction() {
                if (store.state.appConfig.isRTL) {
                    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                    this.dir = true
                    return this.dir
                }
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = false
                return this.dir
            },
        },
        mounted() {
            if (this.$route.params.filter) {
                this.showTable = true
                this.searchRecords()
                this.getContactGp()
            }
            this.getCountries()
            this.totalRows = this.items.length
    
        },
        directives: {
            Ripple,
            heightFade
        },
        created() {
            window.addEventListener('resize', this.initTrHeight)
        },
        destroyed() {
            window.removeEventListener('resize', this.initTrHeight)
        },
        methods: {
            countDownChanged(dismissCountDown) {
                this.dismissCountDown = dismissCountDown
            },
            editUserContact(userData){
                let vm  = this
                vm.phone = userData.msisdn
                vm.custom1 =userData.custom1
                vm.custom2 =userData.custom2
                vm.custom3 =userData.custom3
                vm.custom4 =userData.custom4
                vm.custom5 =userData.custom5
                vm.contactID =  userData.id
                vm.editUserModal = true
            },
             disabledUserContact(userData){
                let vm  = this
                vm.phone = userData.msisdn
                vm.custom1 =userData.custom1
                vm.custom2 =userData.custom2
                vm.custom3 =userData.custom3
                vm.custom4 =userData.custom4
                vm.custom5 =userData.custom5
                vm.contactID =  userData.id
                vm.statusAccount = 0
                vm.disableUserModal = true
            },
            enabledUserContact(userData){
                 let vm  = this
                vm.phone = userData.msisdn
                vm.custom1 =userData.custom1
                vm.custom2 =userData.custom2
                vm.custom3 =userData.custom3
                vm.custom4 =userData.custom4
                vm.custom5 =userData.custom5
                vm.contactID =  userData.id
                vm.statusAccount = 1
                vm.enableUserModal = true
            },
             deleteUserContact(userData){
                let vm  = this
                vm.phone = userData.msisdn
                vm.custom1 =userData.custom1
                vm.custom2 =userData.custom2
                vm.custom3 =userData.custom3
                vm.custom4 =userData.custom4
                vm.custom5 =userData.custom5
                vm.contactID =  userData.id
                vm.statusAccount = -1
                vm.deleteUserModal = true
            },
            editContact(){
    
            },
            
            editContactGroup(status) {
                console.log(" I'm here test message")
                let vm = this
                vm.editIsLoading = true
                for (let i = 0; i < vm.customFields.length; i++) {
                    if (i == 0) {
                        vm.custom1 = vm.customFields[i]
                    }
                    if (i == 1) {
                        vm.custom2 = vm.customFields[i]
                    }
                    if (i == 2) {
                        vm.custom3 = vm.customFields[i]
                    }
                    if (i == 3) {
                        vm.custom4 = vm.customFields[i]
                    }
                    if (i == 4) {
                        vm.custom5 = vm.customFields[i]
                    }
                }
                $('#contactEdit').html(' Please Wait ...');
                $.post({
                    url: store.state.rootUrl + 'contact/v1/list_edit',
                    post: "POST",
                    headers: {
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    data: JSON.stringify({
                        listId:vm.$route.params.filter,
                        listName: vm.group_name,
                        listDesc: vm.description,
                        listCustom1: vm.custom1,
                        listCustom2: vm.custom2,
                        listCustom3: vm.custom3,
                        listCustom4: vm.custom4,
                        listCustom5: vm.custom5,
                        status:status
                    }),
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        $('#contactEdit').html(' Create');
                        if (response.data.code != 200) {
                            console.log("error message " + response.data.message)
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message
    
                        } else {
    
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            
                             vm.isTaskHandlerSidebarActive = false
    
                        }
    
                    },
                    error: function (jQxhr, status, error) {
                        vm.isLoading = false
                        vm.dismissCountDown = vm.dismissSecs
                        vm.showDismissibleAlert = true;
                        $('#contactEdit').html(' Create');
                        var statustext = jQxhr.responseJSON.statusDescription;
                        console.log("json String Test " + statustext)
    
                        if (!statustext) {
                            vm.errorMessage = "An error occurred. Try again later.";
                        } else {
                            vm.errorMessage = statustext;
                        }
                    }
                });
            },
            searchRecords() {
                let vm = this
                vm.showTable = true
                vm.items = []
                vm.getPhoneList()
            },
            getCountries() {
                let vm = this
                $.get({
                    url: store.state.rootUrl + 'account/v1/view/countries',
                    type: "get",
                    async: true,
                    crossDomain: true,
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + store.state.accessToken
                    },
                    success: function (response, status, jQxhr) {
    
                        $('#resetPWD').html(' Reset Password');
                        if (response.data.code != 200) {
                            vm.countries = []
                        } else {
                            let result = response.data.data;
    
                            for (const [key, value] of Object.entries(result)) {
    
                                var country = {
                                    value: `${key}`,
                                    text: `${value[0]}` + " (+" + `${key}` + ")"
    
                                }
                                vm.countryCodeOptions.push(country)
                            }
    
                        }
    
                    },
                    error: function (jQxhr, status, error) {
                        vm.countries = []
                        if (jQxhr.responseJSON.data.code == 401 || jQxhr.responseJSON.data.code == 500 || jQxhr.responseJSON.data.code == 403 || jQxhr.responseJSON.data.code == 404) {
                            vm.$cookies.remove("token");
                            vm.$cookies.remove("clientData");
                            // Redirect to login page
                            vm.$router.push({ name: 'login' })
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: jQxhr.responseJSON.data.message,
                                    icon: 'EditIcon',
                                    variant: 'failed',
                                },
                            })
                        }
                    }
                });
            },
            editContact(){
                let vm = this
                $.post({
                    url: store.state.rootUrl + 'contact/v1/edit_entry/'+vm.contactID,
                    post: "POST",
                    headers: {
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    data: JSON.stringify({
                        status: vm.statusAccount,
                        custom1: vm.custom1,
                        custom2: vm.custom2,
                        custom3: vm.custom3,
                        custom4: vm.custom4,
                        custom5: vm.custom5,
                    }),
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        $('#addUser').html(' Add');
                        if (response.data.code != 200) {
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message
    
                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.searchRecords()
                        }
    
                    },
                    error: function (jQxhr, status, error) {
                        vm.isLoading = false
                        vm.dismissCountDown = vm.dismissSecs
                        var statustext = jQxhr.responseJSON.statusDescription;
                        vm.showDismissibleAlert = true;
                        if (!statustext) {
                            vm.errorMessage = "An error occurred. Try again later.";
                        } else {
                            if (jQxhr.responseJSON.data) {
    
                                vm.errorMessage = statustext + " " + jQxhr.responseJSON.data.message;
                            } else {
                                vm.errorMessage = statustext;
                            }
                        }
                    }
                });
            },
            synContacts() {
                let vm = this
                $('#addUser').html(' Please Wait ...');
                $.post({
                    url: store.state.rootUrl + 'contact/v1/sync',
                    post: "POST",
                    headers: {
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                        "Content-Type": "application/json",
                    },
                    data: JSON.stringify({
                        listId: vm.$route.params.filter,
                        msisdn: vm.phone,
                        custom1: vm.custom1,
                        custom2: vm.custom2,
                        custom3: vm.custom3,
                        custom4: vm.custom4,
                        custom5: vm.custom5,
                    }),
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        $('#addUser').html(' Add');
                        if (response.data.code != 200) {
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message
    
                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.searchRecords()
                        }
    
                    },
                    error: function (jQxhr, status, error) {
                        vm.isLoading = false
                        vm.dismissCountDown = vm.dismissSecs
                        $('#addUser').html(' Add');
                        var statustext = jQxhr.responseJSON.statusDescription;
                        vm.showDismissibleAlert = true;
                        if (!statustext) {
                            vm.errorMessage = "An error occurred. Try again later.";
                        } else {
                            if (jQxhr.responseJSON.data) {
    
                                vm.errorMessage = statustext + " " + jQxhr.responseJSON.data.message;
                            } else {
                                vm.errorMessage = statustext;
                            }
                        }
                    }
                });
            },
            UploadContacts() {
                let vm = this
                var data = new FormData();
                data.append("dialCode", vm.countryCode);
                data.append("listId", vm.$route.params.filter);
                data.append("uploadedFile", vm.file);
                $.ajax({
                    url: store.state.rootUrl + 'contact/v1/upload',
                    type: "POST",
                    data: data,
                    headers: {
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                    },
                    cache: false,
                    contentType: false,
                    processData: false,
    
                    success: function (response, status, jQxhr) {
                        vm.isLoading = false
                        vm.file = ""
                        $('#contactCreate').html(' Create');
                        if (response.data.code != 200) {
                            vm.dismissCountDown = vm.dismissSecs
                            vm.showDismissibleAlert = true
                            vm.errorMessage = response.data.message
    
                        } else {
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                            vm.searchRecords()
    
                        }
    
                    },
                    error: function (jQxhr, status, error) {
                        vm.file = ""
                        vm.isLoading = false
                        vm.dismissCountDown = vm.dismissSecs
                        $('#contactCreate').html(' Create');
                        var statustext = jQxhr.responseJSON.statusDescription;
                        vm.showDismissibleAlert = true;
                        if (!statustext) {
                            vm.errorMessage = "An error occurred. Try again later.";
                        } else {
                            vm.errorMessage = statustext + " " + jQxhr.responseJSON.data.message;
                        }
                    }
                });
    
            },
            showTables() {
    
                if (this.endDate == "" || this.startDate == "") {
                    this.$swal({
                        title: 'Error!',
                        text: ' You need to select the date',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                } else {
                    this.showTable = true
    
                }
            },
            getPhoneList() {
                let vm = this
                vm.items =[]
                vm.totalContacts=0
                vm.isloadingContacts = true
                $.get({
                    url: store.state.rootUrl + 'contact/v1/view/contacts',
                    type: "get",
                    async: true,
                    data: {
                        listId: vm.$route.params.filter,
                        limit: 20
                    },
                    crossDomain: true,
                    headers: {
                        "Content-Type": "application/json",
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                    },
                    success: function (response, status, jQxhr) {
                        vm.isloadingContacts = false
                        $('#resetPWD').html(' Reset Password');
                        if (response.data.code != 200) {
                            vm.items = []
                        } else {
                            let result = response.data.data;
                            vm.totalContacts = response.data.total_count
                            vm.items = result
                            // for (var i = 0; i < result.length; i++) {
                            //  var contact = {
                            //     Phone: result[i].msisdn,
                            //     Custom1: result[i].custom1,
                            //     Custom2: result[i].custom2,
                            //     Custom3: result[i].custom3,
                            //     Custom4: result[i].custom4,
                            //     Custom5: result[i].custom5,
                            //     date:result[i].created_at,
                            // }
                            // vm.items.push(contact)
                            // }
    
                        }
    
                    },
                    error: function (jQxhr, status, error) {
                        vm.isloadingContacts = false
                        vm.countries = []
                    }
                });
            },
            getContactGp() {
                let vm = this
                $.get({
                    url: store.state.rootUrl + 'contact/v1/view/groups',
                    type: "get",
                    async: true,
                    crossDomain: true,
                    headers: {
                        "Content-Type": "application/json",
                        "X-Requested-With": "XMLHttpRequest",
                        "X-Authorization-Key": vm.$cookies.get("token"),
                    },
                    data: {
                        listId: vm.$route.params.filter
                    },
                    success: function (response, status, jQxhr) {
    
                        if (response.data.code != 200) {
    
                        } else {
                            var result = response.data.data[0];
                            vm.group_name = result.group_name
                            vm.description = result.description
                            if(result.custom1 != ""){
                                vm.customFields.push(result.custom1)
                            }
                             if(result.custom2 != ""){
                                vm.customFields.push(result.custom2)
                            }
                             if(result.custom3 != ""){
                                vm.customFields.push(result.custom3)
                            }
                             if(result.custom4 != ""){
                                vm.customFields.push(result.custom4)
                            }
                             if(result.custom5 != ""){
                                vm.customFields.push(result.custom5)
                            }
    
                        }
                    },
                    error: function (jQxhr, status, error) {}
                });
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
            DownloadContacts() {
                let vm = this
                vm.isLoading = true
                $.get({
                    url: store.state.rootUrl + 'contact/v1/view/contacts',
                    type: "get",
                    async: true,
                    data: {
                        listId: vm.$route.params.filter
                    },
                    crossDomain: true,
                    headers: {
                        "Content-Type": "application/json",
                        "X-Authorization-Key": vm.$cookies.get("token"),
                        "X-Requested-With": "XMLHttpRequest",
                    },
                    success: function (response, status, jQxhr) {
    
                        if (response.data.code != 200) {
                            vm.isLoading = false
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'error',
                                },
                            })
    
                        } else {
                            var exportRecipients = [];
                            for (var i = 0; i < response.data.data.length; i++) {
                                var item = {};
                                item.msisdn = response.data.data[i].msisdn;
                                item.custom1 = response.data.data[i].custom1;
                                item.custom2 = response.data.data[i].custom2;
                                item.custom4 = response.data.data[i].custom3;
                                item.custom4 = response.data.data[i].custom4;
                                item.status = response.data.data[i].status;
                                exportRecipients.push(item);
                            }
                            var exportDate = moment().format('DD_MMMM_YYYY_h:mm');
                            vm.JSONToCSVConvertor(exportRecipients, "ContactReport_" + exportDate, 1);
                            vm.isLoading = false
                            vm.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: response.data.message,
                                    icon: 'EditIcon',
                                    variant: 'success',
                                },
                            })
                        }
                    },
                    error: function (jQxhr, status, error) {
                        vm.isLoading = false
                        var statustext = jQxhr.responseJSON.statusDescription;
                        var errorMessage = ""
                        if (!statustext) {
                            errorMessage = "An error occurred. Try again later.";
                        } else {
                            var messageError = ""
                            messageError = jQxhr.responseJSON.data.message
                            if (!messageError) {
                                errorMessage = statustext;
                            } else {
                                errorMessage = statustext + " " + messageError;
                            }
    
                        }
                        vm.$toast({
                            component: ToastificationContent,
                            props: {
                                title: errorMessage,
                                icon: 'EditIcon',
                                variant: 'error',
                            },
                        })
                    }
                });
            },
            JSONToCSVConvertor(JSONData, ReportTitle, ShowLabel) {
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    
                var CSV = '';
                //Set Report title in first row or line
    
                //CSV += ReportTitle + '\r\n\n';
    
                //This condition will generate the Label/Header
                if (ShowLabel) {
                    var row = "";
    
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
    
                        //Now convert each value to string and comma-seprated
                        row += index + ',';
                    }
    
                    row = row.slice(0, -1);
    
                    //append Label row with line break
                    CSV += row + '\r\n\n\n';
                }
    
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
    
                    //2nd loop will extract each column and convert it in string comma-seprated
                    for (var index in arrData[i]) {
                        row += '"' + arrData[i][index] + '",';
                    }
    
                    row.slice(0, row.length - 1);
    
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
    
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
    
                //Generate a file name
                var fileName = "Liden_";
                //this will remove the blank-spaces from the title and replace it with an underscore
                fileName += ReportTitle.replace(/ /g, "_");
    
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
    
                // Now the little tricky part.
                // you can use either>> window.open(uri);
                // but this will not work in some browsers
                // or you will not get the correct file extension
    
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
    
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
    
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                link.remove();
            },
            shortcodeStatus(value) {
                if (value == 0) {
                    return "Pending"
                } else if (value == 1) {
                    return "Active"
                } else {
                    return "Disabled"
                }
            },
        },
        watch: {
            '$route.params.filter': function (id) {
                this.showTable = true
                this.group_name = ""
                 this.getContactGp()
                this.getPhoneList()
               
            }
        },
        filters: {
            formatNumber(value) {
                if (!value) return 0
                return numeral(value).format("0,0");
            },
            formatDate(value) {
                if (!value) return "";
                return moment(value).format("DD MMM YYYY");
            },
            formatStatus(value) {
                if (value == 1) {
                    return "Active"
                } else {
                    return "Disabled"
                }
            },
            shortCodeStatusColor(value) {
                if (value == 1) {
                    return "success"
                } else {
                    return "failed"
                }
            }
        },
    }
    </script>
    
    <style lang="scss" scoped>
    .draggable-task-handle {
        position: absolute;
        left: 8px;
        top: 50%;
        transform: translateY(-50%);
        visibility: hidden;
        cursor: move;
    
        .todo-task-list .todo-item:hover & {
            visibility: visible;
        }
    }
    </style>
    
    <style lang="scss">
    @import "~@core/scss/base/pages/app-todo.scss";
    </style>
    